import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  readonly matSnackBar = inject(MatSnackBar);

  successAlert(text = 'Operation Success') {
    this.matSnackBar.open(text, '');
  }

  failedAlert(text = 'Operation Failed !') {
    this.matSnackBar.open(text, '');
  }

  unauthorizedAlert(text = 'Unauthorized') {
    this.matSnackBar.open(text, '');
  }

  serverErrorAlert(text = 'Server Error') {
    this.matSnackBar.open(text, '');
  }

}
